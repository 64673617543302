.pdf-selected-box{
    background-color: rgba(0, 19, 12, 1);
    padding: 5px;
}

.selected-pdf-name{
    font-family: 'Inter';
font-size: 14px;
font-weight: 500;
line-height: 10px;
letter-spacing: 0em;
text-align: left;
color: rgba(255, 255, 255, 1);
}

.selected-pdf-index{
    font-family: 'Inter';
font-size: 10px;
font-weight: 400;
line-height: 5px;
letter-spacing: 0em;
text-align: left;
color: rgba(255, 255, 255, 1);
}

.pdf-box{
    background-color: rgba(244, 244, 244, 1);
    padding: 5px;
    border: 1px solid rgba(228, 228, 228, 1);
}


.pdf-name{
    font-family: 'Inter';
font-size: 14px;
font-weight: 500;
line-height: 10px;
letter-spacing: 0em;
text-align: left;
color: rgba(37, 37, 37, 1);
}

.pdf-index{
    font-family: 'Inter';
font-size: 10px;
font-weight: 400;
line-height: 5px;
letter-spacing: 0em;
text-align: left;
color: rgba(37, 37, 37, 1);
}

.clear{
    //styleName: Text sm/Semibold;
font-family: 'Inter';
font-size: 14px;
font-weight: 600;
line-height: 20px;
text-align: left;
display: flex;
align-items: center;
}