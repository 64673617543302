/* This styles the entire scrollbar */
::-webkit-scrollbar {
  width: 7px !important; /* width of the vertical scrollbar */
}

/* This styles the track (progress bar) of the scrollbar */
::-webkit-scrollbar-track {
  background: #f1f1f145 !important; /* color of the tracking area */
}

/* This styles the handle (thumb) of the scrollbar */
::-webkit-scrollbar-thumb {
  background: #dedcdca2 !important; /* color of the scroll thumb */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #5555556e !important; /* color of the scroll thumb on hover */
}