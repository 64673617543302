.channel-sidebar {
    border-right: 1px solid #DDD;
    box-sizing: border-box;
    min-width: 15rem;
    max-width: 16rem;
    border-bottom:1px solid rgba(219, 219, 219, 1);

    height: 90vh;

  }




  @media (max-width: 768px) {
    .channel-sidebar {
      width: 100%;
      height: auto;
      position: relative;
    }
  
  }



  @media screen and (max-width: 1023px) {
    /* Styles for mobile screens */
    .channel-sidebar {
      border-right: 1px solid #DDD;
      background-color: white;
      padding: 20px;
      box-sizing: border-box;
      max-width: 16rem;
      z-index: 99999;
      margin-left:0px ;
      border-radius: 10px;
      border: 1px solid #F4F4F4;
      position: fixed;
      height: 90vh;
          /* Transition effect */
          transition: transform 0.3s ease-in-out;
  
          /* Initially, the sidebar is off-screen */
          transform: translateX(-100%);
          z-index: 99999;
    }
    
    /* Class to toggle the sidebar visibility */
  .channel-sidebar-open {
    transform: translateX(0);
  }
}

.spemai-heading{
  font-family: 'Inter';
font-size: 18px;
font-weight: 600;
line-height: 10px;
letter-spacing: -0.005em;
text-align: left;

}


.channel-section{
  overflow-y: hidden;
width: 100%;
height: 80%;
}




.channel-section:hover{
  overflow-y: auto;
width: 100%;
height: 80%;
overscroll-behavior: contain;
}

.channel-header{
  font-family: 'Inter';
font-size: 16px;
font-weight: 500;
line-height: 24px;
letter-spacing: 0em;
text-align: left;

}

.selected-channel{
  background-color: rgba(245, 245, 245, 1);
  font-family: 'Inter';
font-size: 14px;
font-weight: 500;
line-height: 24px;
letter-spacing: 0em;
height: 45px;
padding: 8px 12px 8px 12px;
display: flex;
align-items: center;
text-align: left;
border-radius: 8px;
// --tw-gradient-to: transparent var(--tw-gradient-to-position)
// --tw-gradient-from-position:0%;
// --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.channel{
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  color: rgba(74, 82, 99, 1);
height: 45px;
padding: 8px 12px 8px 12px;
display: flex;
align-items: center;
text-align: left;
position: relative;
white-space: nowrap;
overflow: hidden;

// --tw-gradient-to: transparent var(--tw-gradient-to-position)
// --tw-gradient-from-position:0%;
// --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.channel::after{
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 50px; /* Adjust based on the gradient length you want */
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
}


.list-item {
  background-color: #eee;
  height: 20px;
  margin: 10px 0;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
}

/* Animation */
@keyframes load {
  0% {
    left: -100%;
  }
  50%, 100% {
    left: 100%;
  }
}

.list-item::after {
  content: '';
  display: block;
  position: absolute;
  left: -100%;
  top: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(90deg, transparent, rgba(255,255,255,0.5), transparent);
  animation: load 1.5s infinite;
}