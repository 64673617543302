.doc-history-box{
    background-color: rgba(239, 239, 239, 1);
    font-family: 'Inter';
font-size: 14px;
font-weight: 500;
line-height: 20px;
letter-spacing: 0em;
text-align: left;
padding: 10px;

cursor: pointer;
}

.search-history{
    font-family: 'Inter';
font-size: 15px;
font-weight: 500;
line-height: 24px;
text-align: left;
color: rgba(0, 0, 0, 1);
}
