.main-heading{
    font-family: 'Inter';
font-size: 38px;
font-weight: 700;
line-height: 20px;
letter-spacing: 0em;
text-align: left;

}

.main-sub-heading{
    font-family: 'Inter';
font-size: 14px;
font-weight: 400;
line-height: 20px;
letter-spacing: 0em;
text-align: left;
color: rgba(74, 82, 99, 1);
}

.main-option{
    border: 1px solid rgba(219, 219, 219, 1);
    border-radius: 8px;
    padding: 10px;

    font-family: 'Inter';
font-size: 14px;
font-weight: 500;
line-height: 20px;
letter-spacing: 0em;
text-align: left;

}



.chat-header{
    height: 10vh !important;
    border-bottom:1px solid rgba(219, 219, 219, 1);
}

.chat-heading{
    font-family: 'Inter';
font-size: 16px;
font-weight: 600;
line-height: 10px;
letter-spacing: -0.005em;
text-align: left;
padding-top: 15px;
padding-left: 20px;
}
.presentation{
    flex-direction: column;
    display: flex;
    height: 90vh;
    overflow: auto;
    position: relative;
    transition-duration: .15s;
        transition-property: width;
        transition-timing-function: cubic-bezier(.4,0,.2,1);
        width: 100%;
        border-right:1px solid rgba(219, 219, 219, 1);
        // flex: 1 1 0%;
    }
    .chat-section{
        overflow: hidden;
        flex: 1 1 0%;
    }
    
.chat-section2{
    overflow-y: auto;
width: 100%;
height: 100%;

  /* For Internet Explorer, Edge */
  -ms-overflow-style: none;
  
  /* For Firefox */
  scrollbar-width: none;
  
  /* For Chrome, Safari, and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
}




.bottom{
    // position: fixed;
    bottom: 0;
    width: 100%;
    // height: 230px; /* Height of the bottom div */

}

.chat-input{
    width: 100%;
height: 55px;
border: none;
outline: none;
resize: none;

}

.chat-input2{
  width: 100%;
height: 95px;
border: none;
outline: none;
resize: none;
}

.option-btn{
    border-radius: 8px 0px 0px 0px;
    border: 1px solid rgba(212, 212, 212, 1);
    cursor: pointer;
    
}
.option-btn2{
    border-radius: 0px 8px 0px 0px;
    border: 1px solid rgba(212, 212, 212, 1);
    cursor: pointer;
}
.option-selected{
    background-color: rgba(245, 245, 245, 1);
}

.chat-box{
    border-radius: 0px 8px 8px 8px;
    border: 1px solid rgba(212, 212, 212, 1);
    background-color: white;
}

.option-txt{
    font-family: 'Inter';
font-size: 13px;
font-weight: 400;
line-height: 20px;
letter-spacing: 0em;
text-align: left;
color: rgba(71, 71, 71, 1);

}
.new-chat{
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0.30000001192092896px;
    text-align: center;
    color: rgba(255, 255, 255, 1);
    
height: 38px;
padding: 4px 8px 4px 8px;
border-radius: 8px;
gap: 8px;
box-shadow: 0px 4px 15px 0px rgba(20, 73, 54, 0.2);
background-color: rgba(20, 73, 54, 1);

display: flex;
align-items: center;

}

.save-chat{
color: rgba(0, 146, 58, 1);
display: flex;
align-items: center;

font-family: 'Inter';
font-size: 14px;
font-weight: 500;
line-height: 10px;
letter-spacing: -0.005em;
text-align: left;

}

.menu-options-mobile{
    display: flex !important;
}


.menu-options-desktop{
    display: none !important;
}

.result-setting{
  font-family: 'Inter';
font-size: 13px;
font-weight: 500;
line-height: 20px;
text-align: left;
cursor: pointer;
}

.select-control{
  padding: 5px 10px 10px 14px;
  margin-bottom: 10px;
width: 187px;

}
option{
  font-family: 'Inter';
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}
.select-control:focus{
outline: none !important;
border: none !important;
}

.document-header{
  font-family: 'Inter';
font-size: 18px;
font-weight: 600;
line-height: 23px;
text-align: center;

}

@media screen and (max-width: 1023px) {
    .main-heading{
        font-family: 'Inter';
    font-size: 38px;
    font-weight: 700;
    line-height: 50px;
    letter-spacing: 0em;
    text-align: left;
    
    }

    .main-option{
      border: 1px solid rgba(219, 219, 219, 1);
      border-radius: 8px;
      padding: 10px;
  
      font-family: 'Inter';
  font-size: 9px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  
    }

    .menu-options-desktop{
        display: flex !important;
    }

    .menu-options-mobile{
        display: none !important;
    }
}





























  
  
  
  
  
  /* @media only screen and (width: 2560px) and (height: 1232px){
   
   .chat-view-body{
    height: 79vh;
   }
   
   .chat-view-body-start{
   padding-top: 40vh;
   }
  
  } 
  @media only screen and (width: 1440px) and (height: 1232px){
  
    .chat-view-body{
     height: 79vh;
    }
    
    .chat-view-body-start{
    padding-top: 40vh;
    }
   
   } 
   @media only screen and (width: 1024px) and (height: 1232px){
   
    .chat-view-body{
     height: 79vh;
    }
    .chat-view-body-start{
    padding-top: 40vh;
    }
    .right-head-font{
      font-size: 14px;
      margin-top: 3px
    }
   
   } 
   @media only screen and (width: 768px) and (height: 1232px){
   
    .chat-view-body{
     height: 79vh;
    }
   
    .chat-view-body-start{
    padding-top: 0vh;
    }
    .right-head-font{
      font-size: 12px;
      margin-top: 3px
    }
   } 
   @media only screen and (width: 2560px) and (height: 1600px){
   
    .chat-view-body{
     height: 82vh;
    }
    
    .chat-view-body-start{
    padding-top: 40vh;
    }
   
   } 
   @media only screen and (width: 3072px) and (height: 1920px){
   
    .chat-view-body{
     height: 85vh;
    }
   
    .chat-view-body-start{
    padding-top: 40vh;
    }
   
   } 
   @media only screen and (width: 1920px) and (height: 1080px){
   
    .chat-view-body{
     height: 76vh;
    }
   
    .chat-view-body-start{
    padding-top: 40vh;
    }
   } 
  
    @media only screen and (width: 3840px) and (height: 2160px){
   
    .chat-view-body{
     height: 86vh;
    }
   
    .chat-view-body-start{
    padding-top: 40vh;
    }
   } 
   @media only screen and (width: 2560px) and (height: 1440px){
    
    .chat-view-body{
     height: 81vh;
    }
   
    .chat-view-body-start{
    padding-top: 40vh;
    }
   } 
   @media only screen and (width: 1440px) and (height: 900px){
   
    .chat-view-body{
     height: 72vh;
    }
    .chat-view-body-start{
    padding-top: 40vh;
    }
   } 
   @media only screen and (width: 1366px) and (height: 768px){
   
    .chat-view-body{
     height: 69vh;
    }
    .chat-view-body-start{
    padding-top: 40vh;
    }
   } 
   @media only screen and (width: 1440px) and (height: 1232px){
    
    .chat-view-body{
     height: 79vh;
    }
    .chat-view-body-start{
    padding-top: 40vh;
    }
   } 
   @media only screen and (width: 3840px) and (height: 2160px){
   
    .chat-view-body{
     height: 86vh;
    }
    .chat-view-body-start{
    padding-top: 40vh;
    }
   } 
   @media only screen and (width: 3024px) and (height: 1964px){
   
    .chat-view-body{
     height: 85vh;
    }
    .chat-view-body-start{
    padding-top: 40vh;
    }
   } 
   @media only screen and (width: 3024px) and (height: 1964px){
  
    .chat-view-body{
     height: 85vh;
    }
    .chat-view-body-start{
    padding-top: 40vh;
    }
   } 
   @media only screen and (width: 1536px) and (height: 864px){
    .chat-view-body{
     height:71.5vh;
    }
   } 
   @media only screen and (width: 2048px) and (height: 1152px){
    .chat-view-body{
     height:77.5vh;
    }
   } 
   @media only screen and (width: 2048px) and (height: 1536px){
    .chat-view-body{
     height:81.5vh;
    }
   } 
   @media only screen and (width: 2560px) and (height: 1080px){
    .chat-view-body{
     height:76vh;
    }
   } 
   @media only screen and (width: 1600px) and (height: 1200px){
    .chat-view-body{
     height:78vh;
    }
   } 
   @media only screen and (width: 1680px) and (height: 1050px){
    .chat-view-body{
     height:75.5vh;
    }
   } 
   @media only screen and (width: 1920px) and (height: 1200px){
    .chat-view-body{
     height:78vh;
    }
   } 
   @media only screen and (width: 1280px) and (height: 800px){
    .chat-view-body{
     height:69.5vh;
    }
   } 
   @media only screen and (width: 1600px) and (height: 900px){
    .chat-view-body{
     height:72.5vh;
    }
   } 
   @media only screen and (width: 1360px) and (height: 768px){
    .chat-view-body{
     height:68vh;
    }
   } 
   @media only screen and (width: 1280px) and (height: 1024px){
    .chat-view-body{
     height:75vh;
    }
   } 
   @media only screen and (width: 1280px) and (height: 720px){
    .chat-view-body{
     height:67vh;
    }
   } 
   @media only screen and (width: 1024px) and (height: 768px){
    .chat-view-body{
     height:68.5vh;
    }
   } 
   @media only screen and (width: 3440px) and (height: 1440px){
    .chat-view-body{
     height:81vh;
    }
   } 
   @media only screen and (width: 1152px) and (height: 864px){
    .chat-view-body{
     height:71.5vh;
    }
   } 
   @media only screen and (width: 1280px) and (height: 768px){
    .chat-view-body{
     height:68.5vh;
    }
   } 
   @media only screen and (width: 1366px) and (height: 900px){
    .chat-view-body{
     height:72.5vh;
    }
   } 
   @media only screen and (width: 1400px) and (height: 1050px){
    .chat-view-body{
     height:75.5vh;
    }
   } 
   @media only screen and (width: 1680px) and (height: 945px){
    .chat-view-body{
     height:73.5vh;
    }
   } 
   @media only screen and (width: 2560px) and (height: 2048px){
    .chat-view-body{
     height:85.5vh;
    }
   } 
   @media only screen and (width: 4096px) and (height: 3072px){
    .chat-view-body{
     height:88.5vh;
    }
   } 
   @media only screen and (width: 3200px) and (height: 2048px){
    .chat-view-body{
     height:85.5vh;
    }
   } 
   @media only screen and (width: 3200px) and (height: 2400px){
    .chat-view-body{
     height:86.5vh;
    }
   } 
   @media only screen and (width: 3840px) and (height: 2400px){
    .chat-view-body{
     height:87vh;
    }
   } 
   @media only screen and (width: 2880px) and (height: 1800px){
    .chat-view-body{
     height:83vh;
    }
   } 
   @media only screen and (width: 2880px) and (height: 1864px){
    .chat-view-body{
     height:84vh;
    }
   } 
   @media only screen and (min-width: 5000px){
    .chat-view-body{
     height:100vh;
    }
   } */

   .selected-cv{
    height: 90vh;
    width: 100%;
   }

   .slide-up {
    opacity: 0; /* Start fully transparent */
    animation: slideUp 1s ease-out forwards; /* Adjust timing as needed */
  }
  
  @keyframes slideUp {
    to {
      opacity: 1;
    }
  }