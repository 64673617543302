.history-sidebar {
    border-right: 1px solid #DDD;
    box-sizing: border-box;
    max-width: 16rem;
    min-width: 16rem;
    // border-left:1px solid rgba(219, 219, 219, 1);

    height: 55vh;
  }


  .hide-mobile{
    display: flex !important;
  }



  @media (max-width: 768px) {
    .history-sidebar {
      width: 100%;
      height: auto;
      position: relative;
    }

    .history-section{
      overflow: auto !important;
      width: 100%;
      height: 100%;
      }
    
      .history-section:hover{
        width: 100%;
        height: 100%;
        overflow-y: auto;
        // -webkit-overflow-scrolling: touch;
        // scrollbar-width: thin;
        }
      
  
  }



  @media screen and (max-width: 1023px) {
    /* Styles for mobile screens */
    .history-sidebar {
      border-left: 1px solid #DDD;
      background-color: white;
      padding: 20px;
      box-sizing: border-box;
      max-width: 16rem;
      z-index: 99999;
      margin-right:0px ;
      right: 0;
      border-radius: 10px;
      border: 1px solid #F4F4F4;
      position: fixed;
      height: 90vh;
          /* Transition effect */
          transition: transform 0.3s ease-in-out;
  
          /* Initially, the sidebar is off-screen */
          transform: translateX(100%);
          z-index: 99999;
    }
    
    /* Class to toggle the sidebar visibility */
  .history-sidebar-open {
    transform: translatex(0);
  }
  .hide-mobile{
    display: none !important;
  }
}

.history-section{
  overflow: hidden;
  width: 100%;
  height: 100%;
  }

  .history-section:hover{
    width: 100%;
    height: 100%;
    overflow-y: auto;
    // -webkit-overflow-scrolling: touch;
    // scrollbar-width: thin;
    }
  


  .history-heading{
    font-family: 'Inter';
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  
  }


  .filter-txt{
    font-family: 'Inter';
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  
  }

  .history-box{
    border: 1px solid rgba(219, 219, 219, 1);
    padding: 10px;
    margin: 10px;
    border-radius: 8px;
  }

  .history{
    font-family: 'Inter';
font-size: 14px;
font-weight: 500;
line-height: 20px;
letter-spacing: 0em;
text-align: left;

  }

  .date-txt{
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(109, 109, 109, 1);
    

  }

  .total-chat-txt{
    font-family: 'Inter';
font-size: 14px;
font-weight: 500;
line-height: 20px;
letter-spacing: 0em;
text-align: left;

  }

  .search-box{
    display: flex;
    border: 1px solid rgba(219, 219, 219, 1);
    border-radius: 4px;
    padding: 8px;
    margin: 10px;

    font-family: 'Poppins';
font-size: 12px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0.30000001192092896px;
text-align: center;

  }

  .search-input{
    border: none;
    outline: none;
  }

  .search-input:focus{
    outline: none;
  }

 

  .filter-heading{
    font-family: 'Inter';
font-size: 16px;
font-weight: 600;
line-height: 14px;
text-align: left;
color: rgba(16, 24, 40, 1);
  }

  .filter-label{
    //styleName: Text sm/Medium;
font-family: 'Inter';
font-size: 14px;
font-weight: 500;
line-height: 20px;
text-align: left;
color: rgba(52, 64, 84, 1);
  }

  .date-class{
    padding: 5px 8px 5px 8px;
    margin-right: 10px;
    border-radius: 8px;
    border-color:  rgb(148, 152, 158);
  }