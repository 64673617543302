.chat-dev-left {
    border-right: 1px solid #dbdbdb;
    height: 100%;
    /* height: 86vh; */
  
  }
  
  .chat-dev-right {
    border-left: 1px solid #dbdbdb;
    height: 100%;
    /* height: 86vh; */
   
  }
  
  .head-left,
  .head-right,
  .head-center {
    height: 54px;
    padding: 12px;
    border-bottom: 1px solid #dbdbdb;
   
  }
  
  /* .head-center{
      height: 54px;
      padding: 12px; 
  } */
  
  .grid-padding {
    padding: 0 !important;
    width: 56% !important;
    margin-bottom: 300px;
  }
  
  .grid-padding1 {
    padding-right: 0 !important;
    width: 22% !important;;
  }
  
  .grid-padding2 {
    padding-left: 0 !important;
    width: 22% !important;;
  }
  
  .grid-paddingchaild1 {
    padding-right: 0 !important;
   
  }
  
  .grid-paddingchaild2 {
    padding-left: 0 !important
  }
  
  .left-head-font {
    font-size: 18px;
    font-weight: 600;
  }
  
  .middle-head-font {
    font-size: 14px;
    font-weight: 500;
    color: #00923A;
    cursor: pointer;
  }
  
  
  
  .right-head-font {
    font-size: 16px;
    font-weight: 600;
    float: right;
    margin-top: 1px;
  }
  
  .right-save {
    float: right;
  }
  
  .right-chennel-title,
  .right-chennel-icon {
    float: left;
    font-size: 16px;
    font-weight: 500;
  }
  
  .right-chennel-icon {
    float: right;
  }
  
  .chat-div-select {
    display: flex;
    width: 100%;
    padding: 10px 12px 10px 10px;
    justify-content: start;
    align-items: center;
    gap: 12px;
    border-radius: 8px;
    background: #f5f5f5;
    /* margin-bottom: 12px; */
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
  
  }
  
  .chat-div-not-select {
    display: flex;
    width: 100%;
    padding: 10px 12px 10px 10px;
    justify-content: start;
    align-items: center;
    gap: 12px;
    border-radius: 8px;
  
    /* margin-bottom: 12px; */
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    color: #4A5263;
  }
  
  .search_div {
    width: 100%;
    height: 37px;
    flex-shrink: 0;
    border-radius: 4px;
    border: 1px solid #dbdbdb;
    background: #fff;
    display: flex;
    justify-content: start;
    align-items: center;
  }
  
  .search-input-div {
    width: 100%;
    height: 40px;
    padding: 12px 9px;
    display: flex;
    justify-content: start;
    align-items: center;
    border-right: 1px solid #dbdbdb;
  }
  
  .search-input {
    color: #929292;
    text-align: start;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.3px;
    border: none;
    outline: none;
  }
  
  /* .chat-history {
    padding: 16px 20px;
  } */
  
  .chat-history-count {
    float: left;
    font-size: 14px;
    font-weight: 600;
  }
  
  .chat-container {
    width: 100%;
    overflow-y: scroll;
    height: 90vh;
    overflow: hidden;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  
  .chat-container:hover {
    overflow: auto;
    /* Show scrollbar on hover */
  }
  
  .chat-container::-webkit-scrollbar {
    width: 0;
    /* Hide the scrollbar in WebKit browsers (Chrome, Safari, Edge) */
  }
  
  .chat-div-select-thread {
    width: 100%;
    min-height: 112px;
    max-height: 112px;
    margin-bottom: 12px;
    border: 1px solid #DBDBDB;
    border-radius: 8px;
    background-color: #f5f5f5;
    padding: 12px;
    cursor: pointer;
    /* display: inline-flex; */
  
  }
  
  .chat-div-not-select-thread {
    width: 100%;
    min-height: 112px;
    max-height: 112px;
    margin-bottom: 12px;
    border: 1px solid #DBDBDB;
    border-radius: 8px;
    background-color: white;
    padding: 12px;
    cursor: pointer;
    /* display: inline-flex; */
  }
  
  .chat-div-thread-text {
    text-align: justify;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }
  
  .thread-date {
    color: #6D6D6D;
    font-size: 12px;
    font-weight: 400;
    float: left;
  }
  
  .thread1 {
    /* width: 87% !important; */
    padding-right: 0 !important;
  }
  
  .thread2 {
    /* width: 13% !important; */
    /* padding-left: 0!important; */
  }
  
  .single-thread {
    overflow-x: hidden;
  }
  
  .msg-send-div {
    /* width: 100%; */
    padding: 10px;
    /* height: 12vh; */
    /* border-radius: 0 8px 8px 8px; */
    border-radius: 8px;
    border: 1px solid #d4d4d4;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 20px 0 20px;
  }
  
  .type-div {
    text-align: left;
    width: 100%;
  }
  
  .msg-input {
    background: transparent;
    width: 100%;
    /* color: #929292; */
    color: black;
    text-align: start;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.3px;
    border: none;
    outline: none;
    overflow: hidden;
    resize: none;
    height: 50px;
  }
  .msg-input::placeholder {
    color:  #474747; /* Adjust the color as needed */
  }
  
  .grid-container {
    display: grid;
    gap: 10px;
    /* Adjust the gap between grid items as needed */
    align-items: end;
    /* Align the whole grid container at the end of the cross axis (bottom) */
    /* height: 80vh; */
  }
  
  .find-search {
    float: left;
  }
  
  .find-info-select {
    display: flex;
    width: 100%;
    padding: 10px 12px 10px 24px;
    justify-content: start;
    align-items: center;
    gap: 12px;
    border-radius: 8px 0 0 0;
    background: #f5f5f5;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    border-left: 1px solid #D4D4D4;
    border-top: 1px solid #D4D4D4;
    font-size: 12px;
    font-weight: 500;
    width: 134px;
  }
  
  .find-info-not-select {
    display: flex;
    width: 100%;
    padding: 10px 12px 10px 24px;
    justify-content: start;
    align-items: center;
    gap: 12px;
    border-radius: 8px 0 0 0;
    background: #FFFFFF;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    border-left: 1px solid #D4D4D4;
    border-top: 1px solid #D4D4D4;
    font-size: 12px;
    font-weight: 400;
    width: 134px;
  
  }
  
  .find-doc-select {
    display: flex;
    width: 100%;
    padding: 10px 12px 10px 24px;
    justify-content: start;
    align-items: center;
    gap: 12px;
    border-radius: 0 8px 0 0;
    background: #f5f5f5;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    border-left: 1px solid #D4D4D4;
    border-top: 1px solid #D4D4D4;
    border-right: 1px solid #D4D4D4;
    font-size: 12px;
    font-weight: 500;
    width: 134px;
  
  }
  
  .find-doc-not-select {
    display: flex;
    width: 100%;
    padding: 10px 12px 10px 24px;
    justify-content: start;
    align-items: center;
    gap: 12px;
    border-radius: 0 8px 0 0;
    background: #FFFFFF;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    border-left: 1px solid #D4D4D4;
    border-top: 1px solid #D4D4D4;
    border-right: 1px solid #D4D4D4;
    font-size: 12px;
    font-weight: 400;
    width: 134px;
  }
  
  .footer {
    text-align: center;
    font-size: 14px;
    color: #8B8B8B;
    font-weight: 400;
    margin-top: 14px;
  }
  
  .message-item {
    display: inline;
  }
  
  .item-content-left {
    float: left;
  }
  
  .img_bac {
    width: 22px;
    height: 22px;
    border-radius: 20px;
    background-color: rgb(231, 243, 239);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .icon-image-c {
    width: 22px;
    height: 22px;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
  }
  
  .image_icon {
    padding: 0 !important;
  }
  
  .icon_class {
    display: inline-block;
    margin: 4px;
    float: left;
    margin: 0 6px 0 6px;
  }
  
  .icon_class_send {
    display: inline-block;
    margin: 4px;
    float: right;
    margin: 0;
    border: hidden;
    background-color: transparent;
  }
  
  .icon_sidemenu {
    display: inline-block;
    margin: 4px;
    float: left;
    /* margin: 0 6px 0 6px; */
  }
  
  .icon-sidemenu-img {
    width:24px;
    height: 24px;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
  }
  
  .chennel-name {
    font-size: 16px;
    font-weight: 600;
    margin-top: 1px;
  }
  
  .teees{
    height: 100vh;
  }
  
  
  .chat-view-body {
    /* margin-left: 12px; */
    /* height: 370px; */
    /* height: 60vh; */
    height: calc(100% - 260px) !important;
    width: 100%;
    /* overflow: hidden; */
    overflow-y: scroll;
    /* Hide the scrollbar for various browsers */
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    padding-bottom: 20px;
    padding-top: 20px;
    display: flex;
    flex-direction: column-reverse;
    padding: 20px;
    padding-bottom: 0px;
    /* justify-content: flex-end;
      align-items:flex-start; */
  }
  
  /* .chat-view-body:hover {
    overflow: auto;
  
  } */
  
  .chat-view-body::-webkit-scrollbar {
    width: 0;
    /* Hide the scrollbar in WebKit browsers (Chrome, Safari, Edge) */
  }
  
  .chat-view-body-div {
    /* height: 65.5vh; */
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .chat-view-user-div {
    display: inline-flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 10px;
    right: 0;
    float: right;
    padding-left: 10vw;
  }
  
  .chat-view-user {
    /* width: 56.68%; */
    width: 100%;
    flex-shrink: 0;
    border-radius: 8px;
    /* background: linear-gradient(273deg, #36996f 19.83%, #63a665 88.21%); */
    padding: 20px;
    border: 1px solid #D4D4D4;
  }
  
  .chat-view-user-msg-text {
    color: #252627;
    text-align: left;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 150% */
    letter-spacing: 0.3px;
  }
  
  .chat-view-user-time {
    color: #6C6C6C;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.3px;
  }
  
  .chat-view-bot-div {
    display: inline-flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 10px;
    left: 0;
    float: left;
    padding-right: 10vw;
  }
  
  .chat-view-bot {
    /* width: 56.68%; */
    width: 100%;
    flex-shrink: 0;
    border-radius: 8px;
    background: #F9F9F9;
    padding: 20px;
  
  }
  
  .chat-view-bot-msg-text {
    color: #252627;
    text-align: left;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    /* 150% */
    letter-spacing: 0.3px;
  }
  
  .chat-view-bot-time {
    color: #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.3px;
  }
  
  .typing {
    float: left;
  }
  
  .start-welcome {
    font-size: 38px;
    font-weight: 700;
  }
  
  .start-sub {
    font-size: 14px;
    font-weight: 400;
    color: #4A5263;
  }
  
  .find-doc,
  .find-info {
    display: inline-block;
    width: 178px;
    /* height: 47px; */
    padding: 10px 20px;
    margin: 10px;
    border: 1px solid #DBDBDB;
    border-radius: 8px;
  }
  
  .find-icon {
    display: inline-block;
    margin-right: 5px;
  }
  
  .find-text {
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
  }
  
  .chat-view-body-start {
    /* margin-left: 12px; */
    /* height: 370px; */
    /* height: 60vh; */
    height: 54vh;
    width: 100%;
    /* overflow-y: hidden; */
    scrollbar-width: none;
    -ms-overflow-style: none;
    padding-bottom: 20px;
    padding-top: 134px;
  
  }
  
  .icon-image-c {
    width: 15px;
    height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .img_bac_chat {
    border-radius: 4px;
    background-color: #F9F4ED;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
  }
  
  .img_bac_chat2 {
    border-radius: 4px;
    background-color: #E8F1F9;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
  }
  .channel-list{
    /* width: 100%; */
      height: 69vh;
      overflow-x: hidden;
      width: 17.5vw;
  }

  /* For Internet Explorer and Edge */
  body {
    scrollbar-width: thin;
  }
  
  body::-webkit-scrollbar-thumb {
    background-color: #888;
  }
  .new-chat{
    width: 170px;
    height: 37px;
    background-color: #144936;
    color: #FFFFFF;
    padding: 4px 8px;
    border-radius: 8px;
    border: none;
  
  }
  html, body {
    margin: 0;
    padding: 0;
    height: 100%;
  }
  
  .App {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0;
    padding: 0;
   
    height: 100vh;
    overflow: hidden;
  }
  
  .row {
    display: flex;
    flex: 1;
  }
  
  .chat-history {
    position: fixed;
    /* bottom: 0;  */
    width: 21.6%;
    background: #FAFAFA; 
    padding: 10px 20px 0 20px; 
    height: 87%;
    overflow-y:scroll;
  }
  .footer{
    position: fixed;
    bottom: 0;
    text-align: center;
    font-size: 14px;
    color: #8B8B8B;
    font-weight: 400;
    margin-top: 14px;
  
  }
  
  .center-bottom {
    position: fixed;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    /* width: 100%; */
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .msg-send-div {
    width: 53.7vw;
    /* padding: 10px; */
    height: 90px;
    border-radius: 8px;
    border: 1px solid #d4d4d4;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 0 24px 0;
  }
  
  .type-div {
    width: 100%;
  }
  
  .msg-input {
    width: 100%;
  }
  .left-channels {
    padding: 20px 25px 0 25px;
    position: fixed;
    height: 90vh;
      /* bottom: 0; */
      /* min-height: 85%; */
  }
  .data_test_animation{
    margin-bottom: 0!important;
  }
  .loading_text{
    margin-bottom: 10px;
  }
  
  .accordion-button {
    height: 20px;
    
  }
  .accordion-button:focus {
background:none;
box-shadow: none !important;
  }

  .accordion-button:not(.collapsed) {
background-color: transparent !important;
border: none !important;
outline: none !important;
box-shadow: none !important;
  }
  