.header{
    height: 10vh;
    width: 100%;
    background-color: rgba(0, 19, 12, 1);
    // position: fixed;
}

.logo{
  height: 80px;
}







/* Phone devices (small screens) */
@media (max-width: 767px) {
    /* Your CSS rules for phones */
  }
  
  /* Tablet devices (medium screens) */
  @media (min-width: 768px) and (max-width: 1023px) {
    /* Your CSS rules for tablets */
  }
  